import * as React from "react";

import {
    makeStyles,
    Dropdown,
    Option,
    Input
} from "@fluentui/react-components";

import { Config, SignatureTag } from "../global.config"
import { getSignatureFieldString } from '../services/mergeService';

import Title from './Title';

interface SignatureTagsProps {
    config: Config;
    setToast: (message: string, type: string) => void;
}

const useStyles = makeStyles({
    dropdown: {
        marginBottom: "1rem",
        border: 'none',
        outline: 'none',
        fontSize: '13px',
        backgroundColor: '#1E1E1E',
        width: "20rem",
    },
    searchField: {
        marginBottom: "1rem",
        border: 'none',
        outline: 'none',
        backgroundColor: '#1E1E1E',
        width: "20rem",
    },
    fieldsContainer: {
        maxHeight: "calc(100vh - 340px)",
        overflowY: "auto",
        width: "20rem",
        backgroundColor: '#1E1E1E',
        border: "none",
        borderRadius: "4px"
      },
      fieldItem: {
        padding: ".7rem",
        cursor: "pointer",
        color: '#ffffffd4',
        borderBottom: "1px solid #ffffff21",
        "&:hover": {
          backgroundColor: "#252525",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
      apiName : {
        paddingLeft:".25rem",
        color: "gray"
      }
});

const SignatureTags: React.FC<SignatureTagsProps> = ({ config, setToast }) => {

    React.useEffect(() => {
        handleInit();
    }, [config]);

    const styles = useStyles();

    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [tags, setTags] = React.useState<SignatureTag[]>([]);

    const filteredTags = tags.filter((tag) =>
        !searchTerm || tag.label.toLowerCase().includes(searchTerm.toLowerCase()) || tag.apiName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInit = () => {
        let tags = [];
        config.signers.forEach(signer=>{
            config?.signerToTags[signer].forEach(tag=>{
                tags.push(tag);
            });
        });
        setTags(tags);
    }

    const handleInsertTag = async (tag: SignatureTag) => {

        try {

            await Word.run(async (context) => {

                const range = context.document.getSelection();
                range.load(["font", "style"]);

                await context.sync();
                range.insertOoxml(
                    getSignatureFieldString(`[ ${tag.label} (${tag.apiName}) ]`, `Sign.${tag.apiName}`),
                    Word.InsertLocation.replace
                );

                await context.sync();

                setToast('success', `Segment "${tag.label}" inserted`);
            });

        } catch (e) {
            setToast('error', JSON.stringify(e));
        }

	};


    const handleSearch = (text:string) => {
        setSearchTerm(text);
    }

    return (
        <div>

            <Title text="Add Signature Elements" variant="info"/>

            <Title text={`Tags (${filteredTags.length})`}  variant="section"/>

            <Input className={styles.searchField}
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(event) => handleSearch(event.target.value)}/>

            {filteredTags?.length > 0 &&
                <div className={styles.fieldsContainer}>
                {filteredTags.map((tag) => (
                    <div key={tag.apiName}
                    onClick={() => handleInsertTag(tag)}
                    className={styles.fieldItem}
                    >
                        {tag.label} <span className={styles.apiName}>{tag.apiName}</span>
                    </div>
                ))}
            </div>}
        </div>
    );
};

export default SignatureTags;
