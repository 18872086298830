import { Config } from "../global.config"

export const getSegmentFieldString = (config:Config, label:string, value:string) => {
  return getOOXMLString(`
    <w:r>
        <w:fldChar w:fldCharType="begin"/>
    </w:r>
    <w:r>
        <w:rPr>
          <w:b w:val="true"/>
        </w:rPr>
        <w:instrText xml:space="preserve"> MERGEFIELD ${value} </w:instrText>
    </w:r>
    <w:r>
        <w:fldChar w:fldCharType="separate"/>
    </w:r>
    <w:r>
        <w:rPr>
          <w:b w:val="false"/>
          <w:noProof w:val="true"/>
          <w:color w:val="${config?.segmentTextColor || '000000'}"/>
          <w:highlight w:val="${config?.segmentHighlightColor || 'cyan'}"/>
          ${config?.segmentFont ? getFontFamilyXMLString(config?.segmentFont) : ''}
          <w:sz w:val="${config?.segmentFontSize || 20}"/>
          <w:szCs w:val="${config?.segmentFontSize || 20}"/>
        </w:rPr>
        <w:t>${label}</w:t>
    </w:r>
    <w:r>
        <w:fldChar w:fldCharType="end"/>
    </w:r>
  `);
};

export const getMergeFieldString = (label:string, value:string) => {
  return getOOXMLString(`
    <w:r>
        <w:fldChar w:fldCharType="begin"/>
    </w:r>
    <w:r>
        <w:instrText xml:space="preserve"> MERGEFIELD ${value} \\* MERGEFORMAT </w:instrText>
    </w:r>
    <w:r>
        <w:fldChar w:fldCharType="separate"/>
    </w:r>
    <w:r>
        <w:rPr>
          <w:noProof w:val="true"/>
        </w:rPr>
        <w:t>${label}</w:t>
    </w:r>
    <w:r>
        <w:fldChar w:fldCharType="end"/>
    </w:r>
  `);
};



export const getSignatureFieldString = (label:string, value:string) => {
  return getOOXMLString(`
    <w:r>
        <w:fldChar w:fldCharType="begin"/>
    </w:r>
    <w:r>
        <w:instrText xml:space="preserve"> MERGEFIELD ${value} </w:instrText>
    </w:r>
    <w:r>
        <w:fldChar w:fldCharType="separate"/>
    </w:r>
    <w:r>
        <w:rPr>
          <w:b w:val="false"/>
          <w:noProof w:val="true"/>
          <w:color w:val="000000"/>
          <w:highlight w:val="yellow"/>
          ${getFontFamilyXMLString('Helvetica')}
          <w:sz w:val="20"/>
          <w:szCs w:val="20"/>
        </w:rPr>
        <w:t>${label}</w:t>
    </w:r>
    <w:r>
        <w:fldChar w:fldCharType="end"/>
    </w:r>
  `);
}


const getOOXMLString = (content:string) => {
  return  `<?xml version="1.0" standalone="yes"?><?mso-application progid="Word.Document"?>
  <pkg:package xmlns:pkg="http://schemas.microsoft.com/office/2006/xmlPackage">
    <pkg:part pkg:name="/_rels/.rels" pkg:contentType="application/vnd.openxmlformats-package.relationships+xml" pkg:padding="512">
      <pkg:xmlData>
        <Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
          <Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="word/document.xml"/>
        </Relationships>
      </pkg:xmlData>
    </pkg:part>
    <pkg:part pkg:name="/word/document.xml" pkg:contentType="application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml">
      <pkg:xmlData>
        <w:document xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main">
          <w:body>
            <w:p>
              ${content}
            </w:p>
            </w:body>
          </w:document>
        </pkg:xmlData>
      </pkg:part>
    </pkg:package>`;
}

const getFontFamilyXMLString = (fontFamily: string) => {
  return `<w:rFonts w:ascii="${fontFamily}" w:hAnsi="${fontFamily}" w:cs="${fontFamily}"/>`;
}