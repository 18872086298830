import * as React from "react";

import {
    makeStyles,
    Button
} from "@fluentui/react-components";

import { SalesforceSession } from "../global.config"
import { config } from "../env.config";

import Title from './Title';

interface LoginProps {
    setSession: React.Dispatch<React.SetStateAction<SalesforceSession>>;
    setToast: (message: string, type: string) => void;
}

const useStyles = makeStyles({
    loginButton : {
        marginTop: '6px',
        backgroundColor : '#0078D4',
        width: "20rem",
        '&:hover': {
          backgroundColor: '#005A9E', // Office blue color for focus border
        }
    },
    loginButtonSandbox : {
        marginTop: '24px',
        fontSize : '12px',
        color : 'gray',
        border : 'none',
        backgroundColor : 'transparent',
        width: "20rem",
        '&:hover': {
          backgroundColor: 'transparent', // Office blue color for focus border
        }
    }
});

const Tabs: React.FC<LoginProps> = ({ setSession, setToast }) => {

    const styles = useStyles();

    const sandboxLogin = () =>{
        initiateOAuth('test');
    }

    const prodLogin = () =>{
        initiateOAuth('login');
    }

    const initiateOAuth = (login_subdomain) =>{

        const redirectUri = encodeURIComponent(`${API_ENDPOINT_URL}/oauth-redirect.html`);
        const scopes = encodeURIComponent('api web refresh_token offline_access');
        const authUrl = `https://${login_subdomain}.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${config.SF_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scopes}&prompt=login&state=${login_subdomain}`;

        Office.context.ui.displayDialogAsync(authUrl, { height: 60, width: 30 }, (result) => {
          const dialog = result.value;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any)=>{
            dialog.close();
            processOAuthResponse(arg, login_subdomain);
          });
        });

    };

    const processOAuthResponse = (arg: any, login_subdomain: string) =>{
        const message = arg.message;
        if (message.startsWith('error')) {
            setToast('error', message);
        } else {
          const data = JSON.parse(message);
          setSession({
            access_token : data?.access_token,
            login_url: `https://${login_subdomain}.salesforce.com`,
            refresh_token : data?.refresh_token,
            instance_url : data?.instance_url
          });
        }
    };

    return (
        <div>
            <Title text="Authenticate with Salesforce" variant="info"/>
            <Button
                className={styles.loginButton}
                onClick={prodLogin}>
                Login
            </Button>
            <Button
                className={styles.loginButtonSandbox}
                onClick={sandboxLogin}>
                Login to Sandbox
            </Button>
        </div>
    );
};

export default Tabs;
