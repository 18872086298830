import { SalesforceSession } from "../global.config"
import { config } from "../env.config";

export const getConfiguration = async (session:SalesforceSession) => {
    return await fetchConfigPart(session, 'getConfiguration');
}

const fetchConfigPart = async (session:SalesforceSession, method:string) => {

    let url = `${API_ENDPOINT_URL}/${method}`;

    let request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            instance_url : session.instance_url,
            access_token : session.access_token
        }),
    };

    return handleCallout(url, request);

}

export const createDocumentVersion = async(body:any) => {

    let url = `${API_ENDPOINT_URL}/createDocumentVersion`;

    let request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };

    return handleCallout(url, request);

}

const handleCallout = async (url:string, request:any) => {

    try {
        let response = await fetch(url, request);
        const result = await response.json();

        if (!response.ok) {
            throw result.error;
        }

        return result;

    } catch (err) {
        let errorMessage = err?.message || err;
        if(errorMessage.indexOf('SESSION_EXPIRED') > -1){
            throw 'SESSION_EXPIRED';
        } else if (errorMessage) {
            throw `Fetch error: ${errorMessage}`;
        } else {
            throw 'An unexpected error occurred';
        }
    }
}

export const refreshToken = async (session:SalesforceSession) => {

    if(!session?.refresh_token){
        return null;
    }

    let url = `${API_ENDPOINT_URL}/refreshAccessToken`;

    let request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            refresh_token : session?.refresh_token,
            login_url : session?.login_url
        }),
    };

    try {
        return handleCallout(url, request);
    } catch (e) {
        return null;
    }

}

export const revokeToken = async (session:SalesforceSession) => {
    if(!session?.refresh_token){
        return null;
    }

    let url = `${API_ENDPOINT_URL}/revokeToken`;

    let request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            refresh_token : session?.refresh_token,
            login_url : session?.login_url
        }),
    };

    try {
        return handleCallout(url, request);
    } catch (e) {
        return null;
    }

}
