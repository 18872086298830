import { SalesforceSession } from "../global.config";
import { config } from "../env.config";

export const getConfiguration = async (session:SalesforceSession) => {
    return await fetchConfigPart(session, 'getConfiguration');
}

const fetchConfigPart = async (session:SalesforceSession, method:string) => {
    let url = `${API_ENDPOINT_URL}/${method}`;
    let request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            instance_url : session.instance_url,
            access_token : session.access_token
        }),
    };
    return handleCallout(url, request, session);
}

export const createDocumentVersion = async(session:SalesforceSession, body:any) => {
    let url = `${API_ENDPOINT_URL}/createDocumentVersion`;
    let request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    return handleCallout(url, request, session);
}

const handleCallout = async (url:string, request:any, session?:SalesforceSession, retry:boolean=false) => {
    try {
        let response = await fetch(url, request);
        if (!response.ok) {
            const errorText = await response.text(); // Read the error response body
            throw new Error(`HTTP Error ${response.status}: ${errorText}`);
        }
        const result = await response.json();
        if(retry && session){
            result.session = session;
        }
        return result;

    } catch (e) {
        let errorMessage = e?.message || e;
        if(!retry && session && errorMessage.indexOf('SESSION_EXPIRED') > -1){
            let refreshResponse = await refreshToken(session);
            session.access_token = refreshResponse.access_token;
            let payload = JSON.parse(request.body);
            payload.access_token = session.access_token;
            request.body = JSON.stringify(payload);
            return handleCallout(url, request, session, true);
        } else if (errorMessage) {
            throw `Fetch error: ${errorMessage}`;
        } else {
            throw 'An unexpected error occurred';
        }
    }
}

export const refreshToken = async (session:SalesforceSession) => {
    if(!session?.refresh_token){
        return null;
    }
    let url = `${API_ENDPOINT_URL}/refreshAccessToken`;
    let request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            refresh_token : session?.refresh_token,
            login_url : session?.login_url
        }),
    };
    try {
        return handleCallout(url, request);
    } catch (e) {
        return null;
    }
}

export const revokeToken = async (session:SalesforceSession) => {
    if(!session?.refresh_token){
        return null;
    }

    let url = `${API_ENDPOINT_URL}/revokeToken`;

    let request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            refresh_token : session?.refresh_token,
            login_url : session?.login_url
        }),
    };

    try {
        return handleCallout(url, request);
    } catch (e) {
        return null;
    }

}
